import React, { useContext, useState } from "react";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";
import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import { Store } from "../context/store";
import africanCountries from "../assets/africa";
import logo from '../assets/images/logo.jpg'

function InquiryForm() {
  const store = useContext(Store);
  const [mainUrl] = store.url;

  // State variables for form fields
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [contactMethod, setContactMethod] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [productName, setProductName] = useState("");
  const [exportInterest, setExportInterest] = useState("");
  const [preferredMarket, setPreferredMarket] = useState("");
  const [tradeFrequency, setTradeFrequency] = useState("");
  const [marketInfo, setMarketInfo] = useState("");
  const [marketInfoOther, setMarketInfoOther] = useState(""); 
  const [preferredMarketOther, setPreferredMarketOther] = useState("");

  const [platformFeaturesOther, setPlatformFeaturesOther] = useState(""); 

  const [platformFeatures, setPlatformFeatures] = useState("");
  const [subscriptionInterest, setSubscriptionInterest] = useState("");
  const [platformAccess, setPlatformAccess] = useState("");
  const [platformAccessOther, setPlatformAccessOther] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const isMissingFields =
      fullName.trim() === "" ||
      company.trim() === "" ||
      country.trim() === "" ||
      state.trim() === "" ||
      (contactMethod === "Both" &&
        (email.trim() === "" || phone.trim() === "")) ||
      productName.trim() === "" ||
      exportInterest === "";

    if (isMissingFields) {
      setError("Please fill in all required fields!");
      setTimeout(() => setError(""), 3000);
      setLoading(false);
      return;
    }

    const url = `${mainUrl}/inquiry`;
    const data = {
      fullName,
      company,
      country,
      state,
      contactMethod,
      email,
      phone,
      productName,
      exportInterest,
      preferredMarket:
        preferredMarket === "Other" ? preferredMarketOther : preferredMarket,
      tradeFrequency,
      marketInfo: marketInfo === "Other" ? marketInfoOther : marketInfo,
      platformFeatures:
        platformFeatures === "Other" ? platformFeaturesOther : platformFeatures,
      subscriptionInterest,
      platformAccess:
        platformAccess === "Other" ? platformAccessOther : platformAccess,
    };

    try {
      const response = await fetch(url, {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const res = await response.json();
        // resetFormFields();
        setError(res.msg);
        setLoading(false);
        setTimeout(() => setError(""), 3000);
      } else {
        const err = await response.json();
        setError(err.msg);
        setLoading(false);
        setTimeout(() => setError(""), 3000);
      }
    } catch (error) {
      setError("An error occurred");
      setLoading(false);
      setTimeout(() => setError(""), 3000);
    }
  };

  const resetFormFields = () => {
    setFullName("");
    setCompany("");
    setCountry("");
    setState("");
    setContactMethod("");
    setEmail("");
    setPhone("");
    setProductName("");
    setExportInterest("");
    setPreferredMarket("");
    setTradeFrequency("");
    setMarketInfo("");
    // setExpansionInterest("");
    setPlatformFeatures("");
    setSubscriptionInterest("");
    setPlatformAccess("");
  };

  return (
    <>
      <div className="container my-5 survey">
        <h2 className="text-center mb-4">
          OAT Data and Market Link Platform Survey
        </h2>
        <Form onSubmit={handleFormSubmit}>
          <h4>Section 1: Basic Information</h4>
          <Row>
            <Col md={6}>
              <Form.Group controlId="fullName" className="mb-3">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your full name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Company/Cooperative Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company/cooperative name"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="country" className="mb-3">
                <Form.Label>Country</Form.Label>
                <select
                  className="form-control"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select your country
                  </option>
                  {africanCountries?.map((e, i) => (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="state" className="mb-3">
                <Form.Label>Region/State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your region or state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="contactMethod" className="mb-3">
                <Form.Label>Preferred Method of Contact</Form.Label>
                <Form.Control
                  as="select"
                  value={contactMethod}
                  onChange={(e) => setContactMethod(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Email">Email</option>
                  <option value="Phone">Phone</option>
                  <option value="Both">Both</option>
                </Form.Control>
              </Form.Group>
            </Col>
            {contactMethod === "Email" || contactMethod === "Both" ? (
              <Col md={6}>
                <Form.Group controlId="email" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            ) : null}
            {contactMethod === "Phone" || contactMethod === "Both" ? (
              <Col md={6}>
                <Form.Group controlId="phone" className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter your phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <h4>Section 2: Inquiry Details</h4>
          <Row>
            <Col md={6}>
              <Form.Group controlId="productName" className="mb-3">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter product name"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="exportInterest" className="mb-3">
                <Form.Label>Are you interested in export?</Form.Label>
                <Form.Control
                  as="select"
                  value={exportInterest}
                  onChange={(e) => setExportInterest(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {exportInterest === "Yes" && (
            <Row>
              <Col md={6}>
                <Form.Group controlId="preferredMarket" className="mb-3">
                  <Form.Label>
                    Which market would you like to export to?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={preferredMarket}
                    onChange={(e) => setPreferredMarket(e.target.value)}
                  >
                    <option value="" disabled>
                      Select market
                    </option>
                    <option value="Europe">Europe</option>
                    <option value="Asia">Asia</option>
                    <option value="North America">North America</option>
                    <option value="Middle East">Middle East</option>
                    <option value="Any">Any</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          )}

          <h4>Section 3: Business and Trade Data</h4>
          <Row>
            <Col md={6}>
              <Form.Group controlId="tradeFrequency" className="mb-3">
                <Form.Label>How frequently do you engage in trade?</Form.Label>
                <Form.Control
                  as="select"
                  value={tradeFrequency}
                  onChange={(e) => setTradeFrequency(e.target.value)}
                >
                  <option value="" disabled>
                    Select frequency
                  </option>
                  <option value="One-time only">One-time only</option>
                  <option value="Occasionally">
                    Occasionally (1-3 times per year)
                  </option>
                  <option value="Regularly">
                    Regularly (monthly or quarterly)
                  </option>
                  <option value="Long-term contracts">
                    Long-term contracts
                  </option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="marketInfo" className="mb-3">
                <Form.Label>
                  What kind of market information would be most useful to you?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={marketInfo}
                  onChange={(e) => setMarketInfo(e.target.value)}
                >
                  <option value="" disabled>
                    Select market information
                  </option>
                  <option value="Global pricing trends">
                    Global pricing trends
                  </option>
                  <option value="Buyer data">Buyer data</option>
                  <option value="Competitor analysis">
                    Competitor analysis
                  </option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
              {marketInfo === "Other" && (
                <Form.Group controlId="marketInfoOther" className="mb-3">
                  <Form.Label>Please specify the information</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter information"
                    value={marketInfoOther}
                    onChange={(e) => setMarketInfoOther(e.target.value)}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>

          <h4>Section 4: Platform Feedback</h4>
          <Row>
            <Col md={6}>
              <Form.Group controlId="platformFeatures" className="mb-3">
                <Form.Label>
                  Which features on our platform would be most valuable to you?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={platformFeatures}
                  onChange={(e) => setPlatformFeatures(e.target.value)}
                >
                  <option value="" disabled>
                    Select feature
                  </option>
                  <option value="Price alerts">Price alerts</option>
                  <option value="Buyer contact details">
                    Buyer contact details
                  </option>
                  <option value="Certification resources">
                    Certification resources
                  </option>
                  <option value="Market reports">Market reports</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
              {platformFeatures === "Other" && (
                <Form.Group controlId="platformFeaturesOther" className="mb-3">
                  <Form.Label>Please specify the feature</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter feature"
                    value={platformFeaturesOther}
                    onChange={(e) => setPlatformFeaturesOther(e.target.value)}
                  />
                </Form.Group>
              )}
            </Col>
            <Col md={6}>
              <Form.Group controlId="subscriptionInterest" className="mb-3">
                <Form.Label>
                  Would you be interested in subscribing to a platform that
                  offers this data?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={subscriptionInterest}
                  onChange={(e) => setSubscriptionInterest(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="platformAccess" className="mb-3">
                <Form.Label>
                  How would you prefer to access the platform?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={platformAccess}
                  onChange={(e) => setPlatformAccess(e.target.value)}
                >
                  <option value="" disabled>
                    Select access method
                  </option>
                  <option value="Web">Web</option>
                  <option value="Mobile app">Mobile app</option>
                  <option value="Email">Email</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
              {platformAccess === "Other" && (
                <Form.Group controlId="platformFeaturesOther" className="mb-3">
                  <Form.Label>Please specify the access method you prefer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter access method"
                    value={platformAccessOther}
                    onChange={(e) => setPlatformAccessOther(e.target.value)}
                  />
                </Form.Group>
              )}
            </Col>
          </Row>

          <Button type="submit" className="mt-4" disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
          </Button>
          {error && <p className="mt-3 text-danger">{error}</p>}
        </Form>
      </div>
      <Footer />
      <GoToTop />
    </>
  );
}

export default InquiryForm;
